var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"py-1",attrs:{"multiple":"","small-chips":"","item-text":"name","value":_vm.user_groups,"items":[
		{ value: "signed_out", name: "Signed Out", description: "Visitors not signed in" },
		{ value: "signed_in", name: "Signed In", description: "Users signed in" },
		{ value: "zero_agrees", name: "Zero Agrees", description: "Users signed in but who have not made any agrees" },
		{ value: "active", name: "Active", description: "Users signed in that have made any activity the last 30 days" },
		{ value: "inactive", name: "Inactive", description: "User signed in that have not made any activity the last 30 days" } ],"label":"User groups","filled":"","rounded":"","hide-details":""},on:{"change":_vm.updateUserGroups},scopedSlots:_vm._u([{key:"item",fn:function(ref){
	var item = ref.item;
	var attrs = ref.attrs;
	var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.description)+" ")])])])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }