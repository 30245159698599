<template>
	<div>
		<ExploreCardRecipientList
			v-if="card.type === `recipient_list`"
			:section-index="sectionIndex"
			:card-index="cardIndex"
			:tracking="tracking"
		/>
		<ExploreCardUserList
			v-if="card.type === `user_list`"
			:section-index="sectionIndex"
			:card-index="cardIndex"
			:tracking="tracking"
		/>
		<ExploreCardMultiPost
			v-if="card.type === `multi_post`"
			:section-index="sectionIndex"
			:card-index="cardIndex"
			:tracking="tracking"
		/>
		<ExploreCardSinglePost
			v-if="card.type === `single_post`"
			:section-index="sectionIndex"
			:card-index="cardIndex"
			:tracking="tracking"
		/>
		<ExploreCardSingleLink
			v-if="card.type === `single_link`"
			:section-index="sectionIndex"
			:card-index="cardIndex"
			:tracking="tracking"
		/>
		<ExploreCardShortcut
			v-if="card.type === `shortcut`"
			:section-index="sectionIndex"
			:card-index="cardIndex"
			:tracking="tracking"
		/>
		<ExploreCardTopStory
			v-if="card.type === `top_story`"
			:section-index="sectionIndex"
			:card-index="cardIndex"
			:tracking="tracking"
		/>
	</div>
</template>

<style>
.v-input.text-h4 input {
	max-height: none;
}
</style>

<script>
import ExploreCardRecipientList from "@/components/material/ExploreCardRecipientList"
import ExploreCardUserList from "@/components/material/ExploreCardUserList"
import ExploreCardSinglePost from "@/components/material/ExploreCardSinglePost"
import ExploreCardMultiPost from "@/components/material/ExploreCardMultiPost"
import ExploreCardSingleLink from "@/components/material/ExploreCardSingleLink"
import ExploreCardShortcut from "@/components/material/ExploreCardShortcut"
import ExploreCardTopStory from "@/components/material/ExploreCardTopStory"
import { mapGetters } from 'vuex'

export default {
	components: {
		ExploreCardRecipientList,
		ExploreCardUserList,
		ExploreCardMultiPost,
		ExploreCardSingleLink,
		ExploreCardShortcut,
		ExploreCardTopStory,
		ExploreCardSinglePost,
	},
	computed: {
		...mapGetters([`getExploreSection`,`getExploreSectionCard`]),
		section() {
			return this.getExploreSection(this.sectionIndex)
		},
		card() {
			return this.getExploreSectionCard(this.sectionIndex, this.cardIndex)
		},
	},
	data() {
		return {
			cardItems: {},
			changed: false,
			tracking: {
				views: null,
				clicks: null,
				ctr: null,
			},
			form: {
				_id: null,
				content: {
					title: null,
					icon: null,
					icon_url: null,
					headline: null,
					preamble: null,
				},
				date_created: null,
				date_modified: null,
				enabled: null,
				gradient: {
					from: null,
					to: null,
				},
				image_url: null,
				image_url_mobile: null,
				internal_url: null,
				link_url: null,
				user_groups: [],
				type: [],
			},
		}
	},
	methods: {
		okCard() {
			this.changed = true
		},
	},
	mounted() {
		if (this.card._id) {
			this.form = this.card
			this.$httpInt.get(`/v2/app/feed/explore/section/${this.section._id}/card/${this.card._id}/statistics`)
				.then(res => this.tracking = res.data.result)
		}
	},
	name: `MaterialFeedCard`,
	props: {
		cardIndex: {
			type: Number,
		},
		sectionIndex: {
			type: Number,
		},
	},
}
</script>