<template>
	<v-hover>
		<template v-slot:default="{ hover }">
			<v-container class="pb-0">

				<template v-if="sectionIndex === 0">
					<v-row>
						<v-col align="center">
							<v-menu offset-y>
								<template v-slot:activator="{ on }">
									<Button v-on="on" class="ml-4" small>
										<v-icon>mdi-plus</v-icon>
									</Button>
								</template>
								<v-list>
									<v-list-item @click="newSection(0)">
										<v-list-item-title>Section</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
					</v-row>
				</template>

				<v-row style="min-height: 48px;">
					<v-col class="py-2" align-self="center">
						<template v-if="editing">
							<div class="d-flex">
								<v-text-field
									v-model="edit.divider.title"
									class="py-1 mt-0 pr-1"
									label="Section title"
									@keyup="changed = true"
									filled
									rounded
									hide-details
								/>
								<v-text-field
									class="py-1 pl-1"
									v-model="edit.card_count"
									filled
									rounded
									label="Card Count"
									type="number"
									min="1"
									max="3"
									hide-details
								/>
							</div>
							<ExploreShowAllUrl :show_all_url.sync="edit.show_all_url" :show_all_internal_url="edit.show_all_internal_url" />
						</template>
						<template v-else>
							<h3>{{ section.divider.title }}</h3>
						</template>
					</v-col>
					<v-col class="py-1 text-right">
						<div>
							<template v-if="hover && !editing">
								<v-btn @click="editSection" small class="ma-1"><svg-icon icon="pencil" /></v-btn>
								<v-btn @click="removeSection" small class="ma-1"><svg-icon color="red" icon="trash-can" /></v-btn>
							</template>

							<template v-if="editing">
								<v-btn-toggle
									v-model="edit.randomize"
									color="green"
								>
									<v-btn
										:value="true"
										small
									>
										<svg-icon icon="random" />
									</v-btn>
								</v-btn-toggle>
								<v-btn @click="okSection" elevation="0" small class="ma-1"><svg-icon color="green" icon="check-circle" /></v-btn>
								<v-btn @click="cancelSection" elevation="0" small class="ma-1"><svg-icon color="red" icon="cross-circle" /></v-btn>
							</template>
						</div>
					</v-col>
				</v-row>

				<v-row class="drag">
					<draggable v-model='cardsOrder' class="row">
						<v-col v-for="(card, i) in cards" :cols="calcCols(section, card)" :key="i">
							<ExploreCard
								:card-index="i"
								:section-index="sectionIndex"
							/>
						</v-col>
					</draggable>
				</v-row>

				<v-row>
					<v-col align="center">
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<Button v-on="on" class="ml-4" small>
									<v-icon>mdi-plus</v-icon>
								</Button>
							</template>
							<v-list>
								<v-list-item @click="newSection">
									<v-list-item-title>Section</v-list-item-title>
								</v-list-item>
								<v-divider />
								<v-list-item @click="newCard(`top_story`)" v-if="cards.every(o => [`top_story`].includes(o.type))">
									<v-list-item-title>Top story</v-list-item-title>
								</v-list-item>
								<v-list-item @click="newCard(`shortcut`)" v-if="cards.every(o => [`shortcut`].includes(o.type))">
									<v-list-item-title>Shortcut</v-list-item-title>
								</v-list-item>
								<v-list-item @click="newCard(`single_link`)" v-if="cards.every(o => [`single_link`, `single_post`, `multi_post`].includes(o.type))">
									<v-list-item-title>Single link</v-list-item-title>
								</v-list-item>
								<v-list-item @click="newCard(`single_post`)" v-if="cards.every(o => [`single_link`, `single_post`, `multi_post`].includes(o.type))">
									<v-list-item-title>Single post</v-list-item-title>
								</v-list-item>
								<v-list-item @click="newCard(`multi_post`)" v-if="cards.every(o => [`single_link`, `single_post`, `multi_post`].includes(o.type))">
									<v-list-item-title>Multi post</v-list-item-title>
								</v-list-item>
								<v-list-item @click="newCard(`recipient_list`)" v-if="cards.every(o => [`user_list`, `recipient_list`].includes(o.type))">
									<v-list-item-title>Recipient list</v-list-item-title>
								</v-list-item>
								<v-list-item @click="newCard(`user_list`)" v-if="cards.every(o => [`user_list`, `recipient_list`].includes(o.type))">
									<v-list-item-title>User list</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</v-hover>
</template>

<script>
import ExploreCard from "@/components/material/ExploreCard"
import ExploreShowAllUrl from "@/components/elements/ExploreShowAllUrl"
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
	components: {
		ExploreCard,
		ExploreShowAllUrl,
		draggable,
	},
	computed: {
		...mapGetters([`getExploreSection`]),
		cards() {
			return this.section.cards
		},
		section() {
			return this.getExploreSection(this.sectionIndex)
		},
		cardsOrder: {
			get() {
				return this.cards
			},
			set(value) {
				this.reorderCards({sectionIndex: this.sectionIndex, cards: value})
			}
		}
	},
	data() {
		return {
			editing: false,
			cardItems: {},
			changed: false,
			edit: {
				divider: {
					title: null,
				},
				card_count: null,
				show_all_url: null,
				show_all_internal_url: null,
			}
		}
	},
	methods: {
		...mapActions([`addSection`, `addCard`, `deleteSection`, `reorderCards`, `saveSection`]),
		refresh() {
			if (!this.getExploreSection(this.index).cards) return

			this.getExploreSection(this.index).cards.forEach(o => {
				if (o.type === `recipient_list`) {
					this.$set(this.cardItems, o._id, []);
					this.$httpInt.get(`/wdht${o.internal_url}?limit=3`)
						.then(res => this.cardItems[o._id] = res.data.results)
				}
			})
		},
		calcCols(section, card) {
			if (card.type === `top_story`) return 12
			if (card.type === `shortcut`) return 3

			let cols = 12 / section.cards.filter(o => [`single_post`, `multi_post`, `user_list`, `recipient_list`, `single_link`].includes(o.type)).length
			if (cols > 0) {
				return cols < 4 ? 4 : cols
			} else {
				cols = 12 / section.cards.filter(o => [`shortcut`].includes(o.type)).length
				return cols < 3 ? 3 : cols
			}
		},
		removeSection() {
			this.$confirm(`Delete`, `Are you sure you want to delete this section?`)
				.then(() => this.deleteSection({sectionIndex: this.sectionIndex}), ()=>{})
		},
		okSection() {
			this.editing = false
			this.edit.randomize = !!this.edit.randomize
			this.saveSection({sectionIndex: this.sectionIndex, section: this.edit})
		},
		cancelSection() {
			this.editing = false
		},
		editSection() {
			this.edit = JSON.parse(JSON.stringify(this.section))
			this.editing = true
		},
		newSection(index) {
			this.addSection({
				card_ids: [],
				card_count: 3,
				cards: [],
				divider: {
					title: `Name your new section`
				},
				show_all_url: null,
				show_all_internal_url: null,
				enabled: true,
				index: (index >= 0) ? index : (this.sectionIndex + 1),
				randomize: false,
				user_groups: [
					`signed_out`,
					`signed_in`,
					`zero_agrees`,
					`active`,
					`inactive`,
				]
			})
		},
		newCard(card) {
			const templates = {
				top_story: {
					"content": {
						"text": "Template for Top Story",
						"button_text": "Thanks!",
						"button_icon": "play",
						"button_icon_url": null
					},
					"enabled": true,
					"gradient": {
						"from": "#19CD9B",
						"to": "#FFBB00"
					},
					"image_url": null,
					"image_url_mobile": null,
					"internal_url": null,
					"link_url": null,
					"type": "top_story",
					"user_groups": [
						"signed_out",
						`signed_in`,
						"zero_agrees",
						"active",
						"inactive"
					],
					"index": null,
				},
				shortcut: {
					"content": {
						"title": "Read news",
						"icon": "news",
						"icon_url": null
					},
					"enabled": true,
					"gradient": null,
					"image_url": null,
					"image_url_mobile": null,
					"internal_url": null,
					"link_url": null,
					"type": "shortcut",
					"user_groups": [
						"signed_out",
						`signed_in`,
						"zero_agrees",
						"active",
						"inactive"
					]
				},
				single_link: {
					"content": {
						"headline": "Template for singel list",
						"preamble": "This will show a full size image with a link",
						"play_button": true
					},
					"enabled": true,
					"gradient": {
						"from": "#19CD9B",
						"to": "#FFBB00"
					},
					"image_url": null,
					"image_url_mobile": null,
					"internal_url": null,
					"link_url": null,
					"user_groups": [
						"signed_out",
						`signed_in`,
						"zero_agrees",
						"active",
						"inactive"
					],
					"type": "single_link",
					"index": null,
					"fade": null,
				},
				recipient_list: {
					"content": {
						"headline": "Template for recipient list",
						"preamble": "This will show a list of climat reviews depending of climate type",
						"review_type": null,
					},
					"enabled": true,
					"gradient": {
						"from": "#19CD9B",
						"to": "#FFBB00"
					},
					"image_url": null,
					"image_url_mobile": null,
					"internal_url": null,
					"link_url": null,
					"user_groups": [
						"signed_out",
						`signed_in`,
						"zero_agrees",
						"active",
						"inactive"
					],
					"type": "recipient_list",
					"index": null,
					"fade": null,
				},
				user_list: {
					"content": {
						"headline": "Template for user list",
						"preamble": "This will show a list of users and their ranking order.",
						"date_since": null,
						"type": `agrees`,
						"excluded_at_names": [],
					},
					"enabled": true,
					"gradient": {
						"from": "#55ACEE",
						"to": "#F0553C"
					},
					"image_url": null,
					"image_url_mobile": null,
					"internal_url": null,
					"link_url": null,
					"user_groups": [
						"signed_out",
						`signed_in`,
						"zero_agrees",
						"active",
						"inactive"
					],
					"type": "user_list",
					"index": null,
					"fade": null,
				},
				single_post: {
					"content": {
						"headline": `Template for single post`,
						"preamble": `Most tech giants focus on clean energy while forgetting about their water footprint`,
						"post_id": ``
					},
					"enabled": true,
					"gradient": {
						"from": "#55ACEE",
						"to": "#F0553C"
					},
					"image_url": null,
					"image_url_mobile": null,
					"internal_url": null,
					"link_url": null,
					"type": "single_post",
					"user_groups": [
						"signed_out",
						`signed_in`,
						"zero_agrees",
						"active",
						"inactive"
					],
					"index": null,
					"fade": null,
				},
				multi_post: {
					"content": {
						"headline": "Popular climate love",
						"preamble": "Go tap agree if you like their solutions",
						"ids": [],
						"random": false,
						"type": "trending"
					},
					"enabled": true,
					"gradient": {
						"from": "#55ACEE",
						"to": "#F0553C"
					},
					"image_url": null,
					"image_url_mobile": null,
					"internal_url": null,
					"link_url": null,
					"type": "multi_post",
					"user_groups": [
						"signed_out",
						`signed_in`,
						"zero_agrees",
						"active",
						"inactive"
					],
					"index": null,
					"fade": null,
				},
			}
			this.addCard({card: templates[card], sectionIndex: this.sectionIndex})
		},
	},
	name: `MaterialFeedSection`,
	props: {
		sectionIndex: {
			type: Number,
		}
	}
}
</script>