<template>
	<v-btn-toggle
		:value="randomize"
		@change="$emit('update:randomize', $event)"
		color="green"
	>
		<v-btn
			:value="true"
			small
		>
			<svg-icon icon="random" />
		</v-btn>
	</v-btn-toggle>
</template>

<script>
export default {
	name: `ExploreCardRandomize`,
	props: {
		randomize: {
			default: undefined,
		},
	},
}
</script>