<template>
	<div>
		<template>
			<v-chip
				v-for="(userGroup, i) in userGroups"
				:key="i"
				x-small
				class="mr-1"
			>
				{{items.find(o => o.value === userGroup).name}}
			</v-chip>
		</template>
	</div>
</template>

<script>
export default {
	data() {
		return {
			items:[
				{ value: `signed_out`, name: `Signed Out`, description: `Visitors not signed in` },
				{ value: `signed_in`, name: `Signed In`, description: `Users signed in` },
				{ value: `zero_agrees`, name: `Zero Agrees`, description: `Users signed in but who have not made any agrees` },
				{ value: `active`, name: `Active`, description: `Users signed in that have made any activity the last 30 days` },
				{ value: `inactive`, name: `Inactive`, description: `User signed in that have not made any activity the last 30 days` },
			]
		}
	},
	name: `ExploreCardUserGroups`,
	props: {
		userGroups: {
			type: Array,
			default: () => ([])
		},
	},
}
</script>