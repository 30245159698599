<template>
	<v-text-field
		label="Show all URL"
		:value="show_all_url"
		@change="$emit('update:show_all_url', $event)"
		@keydown="changed = true"
		class="py-1"
		filled
		rounded
		hide-details
	>
		<template v-slot:append>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<span v-bind="attrs" v-on="on">
						<svg-icon v-if="show_all_url" icon="web" />
						<svg-icon v-if="show_all_internal_url" icon="cellphone" />
					</span>
				</template>
				<div v-if="show_all_url"><h4>Show All Link</h4>{{ show_all_url }}</div>
				<div v-if="changed">
					<h4>Show All Internal Link</h4>Needs to be validated on server
				</div>
				<div v-else-if="show_all_internal_url"><h4>Show All Internal Link</h4>{{ show_all_internal_url }}</div>
			</v-tooltip>
		</template>
	</v-text-field>
</template>

<script>
export default {
	data() {
		return {
			changed: false,
		}
	},
	name: `ExploreShowAllUrl`,
	props: {
		show_all_url: {
			default: undefined,
		},
		show_all_internal_url: {
			default: undefined,
		},
	},
}
</script>