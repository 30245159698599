<template>
	<v-hover>
		<template v-slot:default="{ hover }">
			<v-card flat>
				<template v-if="editing">
					<v-row no-gutters>
						<v-col>
							<v-img
								:src="useGradient ? null : edit.image_url"
								:gradient="gradient(edit)"
								content-class="width-auto"
							>
								<v-container class="d-flex flex-column" :style="`height:100%; ${edit.fade && `background: linear-gradient(to bottom, transparent 50%, black 100%)`}`" no-gutters>

									<v-row no-gutters>
										<v-col align="center">
											<div class="d-inline-flex align-start">
												<div class="d-inline-flex">
													<div>
														<v-switch
															v-model="useGradient"
															class="mt-0"
														></v-switch>
													</div>
													<div>
														<v-btn
															small
															@click="chooseGradientFrom"
															:color="edit.gradient && edit.gradient.from"
															class="mx-1"
														/>
														<v-btn
															small
															@click="chooseGradientTo" :color="edit.gradient && edit.gradient.to" class="mx-1" />
													</div>
												</div>
												<ExploreCardRandomize :randomize.sync="edit.randomize" />
											</div>
											<ExploreCardFade :fade.sync="edit.fade" />
										</v-col>
										<ColorPicker ref="colorPicker" />
									</v-row>
									<v-row no-gutters>
										<v-col align-self="end">
											<v-text-field
												v-model="edit.content.headline"
												class="py-1 text-h4"
												placeholder="Headline"
												dark
												filled
												rounded
												hide-details
											/>
											<v-text-field
												v-model="edit.content.preamble"
												class="py-1"
												placeholder="Preamble"
												dark
												dense
												filled
												rounded
												hide-details
											/>
										</v-col>
									</v-row>
								</v-container>

							</v-img>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col>
							<v-container>
								<v-select
									class="py-1"
									v-model="edit.content.type"
									:items="[`trending`, `partner_posts`, `most_agreed_posts`, `recently_commented`, `newest_reviews`, `ids`]"
									label="Type"
									@change="changedType"
									filled
									rounded
									hide-details
								/>
								<v-text-field
									class="py-1"
									v-if="edit.content.type === `most_agreed_posts`"
									type="number"
									min="0"
									v-model="edit.content.max_age"
									label="Last X days"
									filled
									rounded
									hide-details
								/>
								<v-switch
									v-model="edit.content.random"
									class="py-1 mt-0"
									label="Random"
									hide-details
								></v-switch>
								<v-switch
									v-if="edit.content.type === `recently_commented`"
									v-model="edit.content.answered"
									class="py-1 mt-0"
									label="Answered"
									hide-details
								></v-switch>
								<v-switch
									v-if="edit.content.type !== `ids`"
									v-model="edit.content.agree_exclusion"
									class="py-1 mt-0"
									label="Agree Exclusion"
									hide-details
								></v-switch>
								<template v-if="edit.content.type === `ids`">
									<v-text-field
										v-for="(postId, index) in edit.content.ids"
										:key="index"
										label="Post ID"
										v-model="edit.content.ids[index]"
										class="py-1"
										filled
										rounded
										hide-details
									>
										<template slot="append">
											<v-btn icon small @click="removePostId(index)">
												<svg-icon icon="minus" color="red" />
											</v-btn>
											<v-btn icon small @click="newPostId">
												<svg-icon icon="plus" color="green" />
											</v-btn>
										</template>
									</v-text-field>
								</template>
								<ExploreShowAllUrl :show_all_url.sync="edit.show_all_url" :show_all_internal_url="edit.show_all_internal_url" />
								<v-text-field v-if="!useGradient" label="Image URL" v-model="edit.image_url" class="py-1" filled rounded hide-details />
								<v-text-field v-if="!useGradient" label="Image mobile URL" v-model="edit.image_url_mobile" class="py-1" filled rounded hide-details />
								<ExploreUserGroups :user_groups="edit.user_groups" @updateUserGroups="edit.user_groups = $event" />
							</v-container>
						</v-col>
					</v-row>
				</template>

				<template v-else>
					<v-row no-gutters>
						<v-col>
							<v-img
								:src="card.image_url"
								:gradient="gradient(card)"
								:height="card.show_all_url ? `155px` : `186px`"
								content-class="width-auto"
							>
								<v-row style="height: 100%" no-gutters>
									<v-col align-self="end">
										<ExploreCardTracking v-if="card._id" :tracking="tracking" />
										<v-card-text :style="card.fade && `background: linear-gradient(to bottom, transparent 0%, black 100%)`">
											<div class="text-h4 white--text">{{ card.content.headline }}</div>
											<div class="white--text">{{ card.content.preamble }}</div>
										</v-card-text>
									</v-col>
								</v-row>

							</v-img>
						</v-col>
					</v-row>

					<div class="pa-2 pl-0">
						<v-skeleton-loader
							v-for="index in [1,2,3]"
							type="list-item-avatar"
							:key="index"
						/>
					</div>

					<v-row v-if="card.show_all_url" no-gutters>
						<v-col class="caption" color="#8586B0">
							<v-container class="pt-0 pl-4">
								<span style="color:#8586B0;">See more</span>
							</v-container>
						</v-col>
					</v-row>
					<ExploreCardUserGroups :user-groups="card.user_groups" class="pa-1" />
				</template>

				<!-- Menu -->
				<v-row no-gutters v-if="editing">
					<v-col align="right">
						<v-btn icon @click="okCard" color="green">
							<svg-icon style="font-size: 24px;" icon="check-circle" />
						</v-btn>
						<v-btn icon @click="cancelCard" color="red">
							<svg-icon style="font-size: 24px;" icon="cross-circle" />
						</v-btn>
					</v-col>
				</v-row>

				<v-overlay v-if="hover && !editing" absolute>
					<div align="center" class="font-weight-bold">
						{{ card.type.replace(`_`, ` `) | capitalize }}
					</div>
					<div align="center">
						<template v-if="card.index === null">
							Position: <svg-icon icon="random" />
						</template>
						<template v-else>
							Position: {{card.index + 1}}
						</template>
					</div>
					<v-btn @click="statisticsOverlay = !statisticsOverlay" small class="ma-1"><svg-icon icon="forecast-chart" /></v-btn>
					<v-btn @click="editCard" small class="ma-1"><svg-icon icon="pencil" /></v-btn>
					<v-btn @click="removeCard" small class="ma-1" color="red"><svg-icon icon="trash-can" /></v-btn>
				</v-overlay>

				<v-overlay
					:absolute="true"
					:value="statisticsOverlay"
					class="fullscreen"
					:dark="$vuetify.theme.dark"
				>
					<ExploreStatistics :card-id="card._id" :section-id="section._id" @showStatisticsOverlay="statisticsOverlay = $event" />
				</v-overlay>
			</v-card>
		</template>
	</v-hover>
</template>

<style>
.width-auto {
	width: auto !important;
}
</style>

<script>
import ColorPicker from "@/components/dialogs/ColorPicker"
import ExploreUserGroups from "@/components/material/ExploreUserGroups"
import ExploreCardRandomize from "@/components/elements/ExploreCardRandomize"
import ExploreCardFade from "@/components/elements/ExploreCardFade"
import ExploreShowAllUrl from "@/components/elements/ExploreShowAllUrl"
import ExploreCardTracking from "@/components/elements/ExploreCardTracking"
import ExploreCardUserGroups from "@/components/elements/ExploreCardUserGroups"
import ExploreStatistics from "@/components/material/ExploreStatistics"
import {mapActions, mapGetters} from "vuex";

export default {
	components: {
		ColorPicker,
		ExploreUserGroups,
		ExploreCardRandomize,
		ExploreCardFade,
		ExploreShowAllUrl,
		ExploreCardTracking,
		ExploreCardUserGroups,
		ExploreStatistics,
	},
	computed: {
		...mapGetters([`getExploreSection`,`getExploreSectionCard`]),
		section() {
			return this.getExploreSection(this.sectionIndex)
		},
		card() {
			return this.getExploreSectionCard(this.sectionIndex, this.cardIndex)
		},
	},
	data() {
		return {
			cardItems: {},
			statisticsOverlay: false,
			editing: false,
			useGradient: false,
			edit: {
				_id: null,
				content: {
					headline: null,
					preamble: null,
					type: null,
					ids: [],
					random: null,
					answered: null,
					max_age: null,
					agree_exclusion: null,
				},
				date_created: null,
				date_modified: null,
				enabled: null,
				gradient: {},
				image_url: null,
				image_url_mobile: null,
				internal_url: null,
				link_url: null,
				show_all_url: null,
				show_all_internal_url: null,
				user_groups: [],
				type: [],
				index: null,
				randomize: null,
				fade: null,
			},
		}
	},
	methods: {
		...mapActions([`deleteCard`, `saveCard`]),
		removePostId(index) {
			this.edit.content.ids.splice(index, 1)
		},
		newPostId() {
			let content = this.edit.content
			content.ids.push(``)
			this.edit.content = JSON.parse(JSON.stringify(content))
		},
		changedType() {
			if (this.edit.content.type === `ids`) {
				this.edit.content.ids = [``]
			}

			if (this.edit.content.type.includes([`trending`, `partner_posts`, `most_agreed_posts`, `recently_commented`, `recently_commented_answered`, `newest_reviews`])) {
				delete this.edit.content.ids
			}
		},
		gradient(card) {
			if (this.useGradient) {
				return `to top right, ${card.gradient && card.gradient.from || `#333`}, ${card.gradient && card.gradient.to || `#CCC`}`
			}
			if (card.image_url) {
				return null
			} else {
				return `to top right, ${card.gradient && card.gradient.from || `#333`}, ${card.gradient && card.gradient.to || `#CCC`}`
			}
		},
		okCard() {
			this.editing = false
			if (this.useGradient) {
				this.edit.image_url = null
				this.edit.image_url_mobile = null
			} else {
				this.edit.gradient = null
			}
			this.saveCard({sectionIndex: this.sectionIndex, cardIndex: this.cardIndex, card: this.edit})
		},
		cancelCard() {
			this.editing = false
		},
		editCard() {
			this.edit = JSON.parse(JSON.stringify(this.card))
			this.edit.randomize = this.edit.index === null
			this.editing = true
			this.useGradient = this.edit.gradient !== null
		},
		removeCard() {
			this.$confirm(`Delete`, `Are you sure you want to delete this card?`)
				.then(() => this.deleteCard({sectionIndex: this.sectionIndex, cardIndex: this.cardIndex}), ()=>{})
		},
		chooseGradientFrom() {
			this.$refs.colorPicker.open(this.edit.gradient && this.edit.gradient.from)
				.then(res => {
					if (!this.edit.gradient) {
						this.edit.gradient = { from: null, to: null}
					}
					this.edit.gradient.from = res.hex || res
				})
		},
		chooseGradientTo() {
			this.$refs.colorPicker.open(this.edit.gradient && this.edit.gradient.to)
				.then(res => {
					if (!this.edit.gradient) {
						this.edit.gradient = { from: null, to: null}
					}
					this.edit.gradient.to = res.hex || res
				})
		},
	},
	name: `MaterialFeedCardMultiPost`,
	props: {
		sectionIndex: {
			type: Number,
		},
		cardIndex: {
			type: Number,
		},
		tracking: {
			type: Object,
			default: () => ({})
		},
	},
}
</script>