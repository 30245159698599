<template>
	<div style="position: absolute; top:0px; right:0px;" class="mr-3 mt-3 white--text">
		<svg-icon icon="ctr" />
		<span v-if="tracking.ctr !== null && tracking.ctr >= 0">{{ tracking.ctr }}%</span>
		<v-progress-circular
			v-else
			size="14"
			indeterminate
		></v-progress-circular>
	</div>
</template>

<script>
export default {
	name: `ExploreCardTracking`,
	props: {
		tracking: {
			type: Object,
			default: () => ({})
		},
	},
}
</script>