<template>
	<v-container class="d-inline-flex">
		<div>
			<Avatar
				:source="post.movement.profile.small_profile_image"
				:sub-source="post.movement.type"
			/>
		</div>
		<div class="text-truncate pl-2">
			<div class="text-truncate">
				{{ post.title }}
			</div>
			<div class="text-truncate caption">
				{{ post.text }}
			</div>
		</div>
	</v-container>
</template>

<script>
import Avatar from "@/components/elements/Avatar";

export default {
	components: {
		Avatar
	},
	data() {
		return {
			cardItems: {}
		}
	},
	name: `ExploreListPost`,
	methods: {

	},
	props: {
		post: {
			required: true,
		},
	},
}
</script>