<template>
	<v-dialog v-model="show" max-width="350" @keydown.esc="cancel">
		<v-card>
			<v-card-title>
				Color Picker
			</v-card-title>

			<v-card-text>
				<v-row no-gutters>
					<v-col>
						<v-color-picker v-model="color" />
					</v-col>
				</v-row>

				<v-row no-gutters>
					<v-col align="right">
						<v-btn @click="accept">
							Choose
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

	</v-dialog>
</template>

<script>

export default {
	data() {
		return {
			color: null,
			show: false,
		}
	},
	name: `ColorPicker`,
	methods: {
		open(color) {
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
				this.reset()
				this.color = color
				this.show = true
			})
		},
		close() {
			this.show = false
		},
		accept() {
			this.close()
			this.resolve(this.color)
		},
		cancel() {
			this.reject()
			this.close()
		},
		reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
	},
}
</script>