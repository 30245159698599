<template>
	<v-container
		fluid
	>
		<v-btn
			v-if="getExploreSections().length > 0"
			@click="save"
			:loading="saving"
		>
			Save
		</v-btn>
		<v-container class="drag">
			<draggable v-model='sectionsOrder' class="row">
				<template v-if="getExploreSections().length > 0">
					<MaterialFeedSection
						ref="sections"
						v-for="(section, i) in getExploreSections()"
						:key="i"
						:section-index="i"
					/>
				</template>
				<Button
					v-else
					@click="newSection(0)"
					class="ml-4"
					small
				>
					<v-icon>mdi-plus</v-icon>
				</Button>
			</draggable>
		</v-container>
	</v-container>
</template>

<script>
import MaterialFeedSection from "@/components/material/ExploreSection";
import draggable from "vuedraggable";
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

export default {
	components: {
		MaterialFeedSection,
		draggable,
	},
	computed: {
		...mapGetters([`getExploreSections`]),
		sectionsOrder: {
			get() {
				return this.getExploreSections()
			},
			set(value) {
				this.reorderSections({sections: value})
			}
		}
	},
	data() {
		return {
			sections: [],
			saving: false,
		}
	},
	methods: {
		...mapActions([`addSection`, `setSections`, `reorderSections`]),
		newSection(index) {
			this.addSection({
				card_ids: [],
				cards: [],
				divider: {
					title: `Name your new section`
				},
				enabled: true,
				index: index,
				user_groups: [
					`signed_out`,
					`signed_in`,
					`zero_agrees`,
					`active`,
					`inactive`,
				]
			})
		},
		refresh() {
			this.$httpInt.get(`/v2/app/feed/explore?sort_by=index`)
				.then(res => this.setSections(res.data.result))
		},
		save() {
			this.saving = true
			this.$httpInt.post(`/v2/app/feed/explore`, { sections: this.getExploreSections() })
				.then(() => {
					this.$root.$snackbar(`Successfully saved!`, {color:`success`})
					//this.setSections(res.data.result)
				})
				.finally(() => this.saving = false)
		},
	},
	mounted() {
		this.refresh()
	},
	name: `FeedExplore`,
}
</script>
