<template>
	<v-switch
		:input-value="fade"
		@change="$emit('update:fade', $event)"
		class="mt-0"
		label="Fade"
		:false-value="false"
		:true-value="true"
		dark
	></v-switch>
</template>

<script>
export default {
	name: `ExploreCardFade`,
	props: {
		fade: {
			default: undefined,
		},
	},
}
</script>