<template>
	<v-menu
		ref="menu"
		v-model="show"
		:close-on-content-click="false"
		:return-value.sync="date"
		transition="scale-transition"
		offset-y
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				class="py-1"
				:value="formatDate"
				:label="label"
				readonly
				v-bind="attrs"
				v-on="on"
				filled
				rounded
				hide-details
			></v-text-field>
		</template>
		<v-date-picker
			:value="date"
			@change="accept($event)"
			no-title
			scrollable
		>
			<v-spacer />
			<v-btn
				text
				@click="reset"
			>
				Reset
			</v-btn>
		</v-date-picker>
	</v-menu>
</template>

<script>
export default {
	computed: {
		formatDate() {
			return this.date ? this.dayjs(this.date).format(this.format) : ``
		},
	},
	data() {
		return {
			changed: false,
			show: false,
		}
	},
	methods: {
		accept(event) {
			this.$emit('update:date', event)
			this.$nextTick(() => this.$refs.menu.save(event))
		},
		reset() {
			this.$emit('update:date', null)
			this.$nextTick(() => this.$refs.menu.save(null))
		},
	},
	name: `ExploreDatePicker`,
	props: {
		date: {
			default: null,
		},
		label: {
			default: ``,
		},
		format: {
			default: `YYYY-MM-DD`,
		},
	},
}
</script>