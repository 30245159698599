<template>
	<v-autocomplete
		class="py-1"
		multiple
		small-chips
		item-text="name"
		:value="user_groups"
		@change="updateUserGroups"
		:items="[
			{ value: `signed_out`, name: `Signed Out`, description: `Visitors not signed in` },
			{ value: `signed_in`, name: `Signed In`, description: `Users signed in` },
			{ value: `zero_agrees`, name: `Zero Agrees`, description: `Users signed in but who have not made any agrees` },
			{ value: `active`, name: `Active`, description: `Users signed in that have made any activity the last 30 days` },
			{ value: `inactive`, name: `Inactive`, description: `User signed in that have not made any activity the last 30 days` },
		]"
		label="User groups"
		filled
		rounded
		hide-details
	>
		<template v-slot:item="{ item, attrs, on }">
			<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
				<v-list-item-action>
					<v-checkbox :input-value="active" />
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>
						<v-row no-gutters align="center">
							<div>
								<div>
									{{ item.name }}
								</div>
								<div class="caption">
									{{ item.description }}
								</div>
							</div>
						</v-row>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>

<script>

export default {
	data() {
		return {
			color: null,
		}
	},
	methods: {
		updateUserGroups(newData) {
			/* eslint-disable-next-line */
			this.$emit('updateUserGroups', newData)
		},
	},
	name: `ExploreUserGroups`,
	props: {
		user_groups: {
			type: Array,
			default: ()=>[]
		}
	},
}
</script>