<template>
	<v-autocomplete
		class="py-1"
		:value="at_names"
		@change="changeList($event)"
		:loading="loading"
		:items="[...items, ...at_names]"
		:search-input.sync="search"
		multiple
		cache-items
		small-chips
		auto-select-first
		:label="label"
		filled
		rounded
		hide-details
	></v-autocomplete>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
			items: [],
			search: null,
		}
	},
	methods: {
		changeList(event) {
			this.$emit('update:at_names', event)
		},
		querySelections(v) {
			this.loading = true
			Promise.all([
				this.$httpInt.get(`/v2/app/account/?q=${v}&type=private`),
				this.$httpInt.get(`/v2/app/account/?q=${v}&type=public`),
			]).then(data => {
				let list = []
				data.forEach(o => list.push(...o.data.result.map(o => o.at_name)))
				this.items = list.sort()
			})
			.finally(() => this.loading = false)
		},
	},
	name: `ExploreProfilesList`,
	props: {
		at_names: {
			type: Array,
		},
		label: {
			type: String,
			default: `Profiles`
		},
	},
	watch: {
		search(val) {
			val && val !== this.select && this.$debounce(() => this.querySelections(val), 300)
		},
	},
}
</script>