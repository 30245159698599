<template>
	<v-hover>
		<template v-slot:default="{ hover }">
			<v-card flat>
				<template v-if="editing">
					<v-row no-gutters>
						<v-col>
							<v-img
								:src="edit.image_url"
								height="400px"
								content-class="width-auto"
							>
								<v-container>
									<v-row>
										<v-col align="center">
											<ExploreCardRandomize :randomize.sync="edit.randomize" />
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col>
											<v-text-field
												v-model="edit.content.text"
												class="py-0 text-h4"
												placeholder="Top Story text"
												dark
												filled
												rounded
											/>
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col align="center">
											<v-select
												:items="icons"
												v-model="edit.content.button_icon"
												label="Button Icon"
												dark
												filled
												rounded
												style="width: 150px;"
												class="d-inline-flex mr-1"
											>
											</v-select>
											<v-text-field
												v-model="edit.content.button_text"
												class="py-0 d-inline-flex ml-1"
												label="Button text"
												dark
												filled
												rounded
												style="width: 200px;"
											/>
										</v-col>
									</v-row>
								</v-container>
							</v-img>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col>
							<v-container>
								<v-text-field label="Link URL" v-model="edit.link_url" class="py-1" filled rounded hide-details />
								<v-text-field label="Image url" v-model="edit.image_url" class="py-1" filled rounded hide-details />
								<v-text-field label="Mobile Image url" v-model="edit.image_url_mobile" class="py-1" filled rounded hide-details />
								<ExploreUserGroups :user_groups="edit.user_groups" @updateUserGroups="edit.user_groups = $event" />
							</v-container>
						</v-col>
					</v-row>
				</template>

				<template v-else>
					<v-row no-gutters>
						<v-col>
							<v-img
								:src="card.image_url"
								class="align-top text-center"
								height="320px"
								content-class="width-auto"
							>
								<v-container class="ma-4" style="width: auto;">
									<ExploreCardTracking v-if="card._id" :tracking="tracking" />
									<div style="max-width: 550px;margin: 0 auto;">
										<div class="text-h4 white--text">{{ card.content.text }}</div>
										<v-btn class="mt-3">{{ card.content.button_text }}</v-btn>
									</div>
								</v-container>
							</v-img>
						</v-col>
					</v-row>
					<ExploreCardUserGroups :user-groups="card.user_groups" class="pa-1" />
					<v-row  v-for="(post, i) in cardItems[card._id]" :key="i">
						<v-col class="py-0">
							<ExploreListPost :post="post" />
						</v-col>
					</v-row>
				</template>


				<!-- Menu -->
				<v-row no-gutters v-if="editing">
					<v-col align="right">
						<v-btn icon @click="okCard" color="green">
							<svg-icon style="font-size: 24px;" icon="check-circle" />
						</v-btn>
						<v-btn icon @click="cancelCard" color="red">
							<svg-icon style="font-size: 24px;" icon="cross-circle" />
						</v-btn>
					</v-col>
				</v-row>

				<v-overlay v-if="hover && !editing" absolute>
					<div align="center" class="font-weight-bold">
						{{ card.type.replace(`_`, ` `) | capitalize }}
					</div>
					<div align="center">
						<template v-if="card.index === null">
							Position: <svg-icon icon="random" />
						</template>
						<template v-else>
							Position: {{card.index + 1}}
						</template>
					</div>
					<v-btn @click="statisticsOverlay = !statisticsOverlay" small class="ma-1"><svg-icon icon="forecast-chart" /></v-btn>
					<v-btn @click="editCard" small class="ma-1"><svg-icon icon="pencil" /></v-btn>
					<v-btn @click="removeCard" small class="ma-1" color="red"><svg-icon icon="trash-can" /></v-btn>
				</v-overlay>

				<v-overlay
					:absolute="true"
					:value="statisticsOverlay"
					class="fullscreen"
					:dark="$vuetify.theme.dark"
				>
					<ExploreStatistics :card-id="card._id" :section-id="section._id" @showStatisticsOverlay="statisticsOverlay = $event" />
				</v-overlay>
			</v-card>
		</template>
	</v-hover>
</template>

<style>
.width-auto {
	width: auto !important;
}
</style>

<script>
import ExploreListPost from "@/components/material/ExploreListPost"
import ExploreCardRandomize from "@/components/elements/ExploreCardRandomize"
import ExploreUserGroups from "@/components/material/ExploreUserGroups"
import ExploreCardTracking from "@/components/elements/ExploreCardTracking"
import ExploreCardUserGroups from "@/components/elements/ExploreCardUserGroups"
import ExploreStatistics from "@/components/material/ExploreStatistics"
import {mapActions, mapGetters} from "vuex";

export default {
	components: {
		ExploreListPost,
		ExploreUserGroups,
		ExploreCardRandomize,
		ExploreCardTracking,
		ExploreCardUserGroups,
		ExploreStatistics,
	},
	computed: {
		...mapGetters([`getExploreSection`,`getExploreSectionCard`]),
		section() {
			return this.getExploreSection(this.sectionIndex)
		},
		card() {
			return this.getExploreSectionCard(this.sectionIndex, this.cardIndex)
		},
	},
	data() {
		return {
			cardItems: {},
			statisticsOverlay: false,
			editing: false,
			icons: [
				{
					text: `[Empty]`,
					value: null,
				},
				{
					text: `Agree`,
					value: `agree`,
				},
				{
					text: `Star`,
					value: `star`,
				},
				{
					text: `News`,
					value: `news`,
				},
				{
					text: `Play`,
					value: `play`,
				},
			],
			edit: {
				_id: null,
				content: {
					text: null,
					button_text: null,
					button_icon: null,
					button_icon_url: null,
				},
				date_created: null,
				date_modified: null,
				enabled: null,
				gradient: null,
				image_url: null,
				image_url_mobile: null,
				internal_url: null,
				link_url: null,
				user_groups: [],
				type: [],
				index: null,
				randomize: null,
			},
		}
	},
	methods: {
		...mapActions([`deleteCard`, `saveCard`]),
		okCard() {
			this.editing = false
			this.saveCard({sectionIndex: this.sectionIndex, cardIndex: this.cardIndex, card: this.edit})
		},
		cancelCard() {
			this.editing = false
		},
		editCard() {
			this.edit = JSON.parse(JSON.stringify(this.card))
			this.edit.randomize = this.edit.index === null
			this.editing = true
		},
		removeCard() {
			this.$confirm(`Delete`, `Are you sure you want to delete this card?`)
				.then(() => this.deleteCard({sectionIndex: this.sectionIndex, cardIndex: this.cardIndex}), ()=>{})
		},
	},
	name: `MaterialFeedCardTopStory`,
	props: {
		sectionIndex: {
			type: Number,
		},
		cardIndex: {
			type: Number,
		},
		tracking: {
			type: Object,
			default: () => ({})
		},
	},
}
</script>