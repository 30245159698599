<template>
	<v-hover>
		<template v-slot:default="{ hover }">
			<v-card flat :dark="isDarkMode()">
				<template v-if="editing">
					<v-card dark flat style="background-color: #F0553C; min-height: 84px;">
						<v-row no-gutters>
							<v-col>
								<v-container>
									<v-select
										class="pb-1"
										:items="icons"
										v-model="edit.content.icon"
										label="Icon"
										style="width: 100px;"
										filled
										rounded
										hide-details
									>
										<template v-slot:selection="{ item }">
											<svg-icon :icon="item" />
										</template>
										<template v-slot:item="{ item }">
											<svg-icon :icon="item" />
										</template>
									</v-select>
									<v-text-field
										v-model="edit.content.title"
										class="pt-1"
										label="Title"
										dark
										filled
										rounded
										hide-details
									/>
								</v-container>
							</v-col>
						</v-row>
					</v-card>
					<v-row no-gutters>
						<v-col>
							<v-container>
								<v-text-field
									label="Link URL"
									v-model="edit.link_url"
									class="pb-1"
									filled
									rounded
									hide-details
								/>
								<ExploreUserGroups :user_groups="edit.user_groups" @updateUserGroups="edit.user_groups = $event" />
							</v-container>
						</v-col>
					</v-row>
				</template>


				<template v-else>
					<v-card  dark flat style="background-color: #F0553C; min-height: 84px;">
						<v-row no-gutters >
							<v-col>
								<ExploreCardTracking v-if="card._id" :tracking="tracking" />
								<v-card-text class="py-2">
									<span class="text-h6">
										<svg-icon :icon="card.content.icon" />
									</span>
									<div class="text-h6">{{ card.content.title }}</div>
								</v-card-text>
							</v-col>
						</v-row>
						<v-row  v-for="(post, i) in cardItems[card._id]" :key="i">
							<v-col class="py-0">
								<ExploreListPost :post="post" />
							</v-col>
						</v-row>
					</v-card>
					<ExploreCardUserGroups :user-groups="card.user_groups" class="pa-1" />
				</template>


				<!-- Menu -->
				<v-row no-gutters v-if="editing">
					<v-col align="right">
						<v-btn icon @click="okCard" color="green">
							<svg-icon style="font-size: 24px;" icon="check-circle" />
						</v-btn>
						<v-btn icon @click="cancelCard" color="red">
							<svg-icon style="font-size: 24px;" icon="cross-circle" />
						</v-btn>
					</v-col>
				</v-row>

				<v-overlay v-if="hover && !editing" absolute>
					<div align="center" class="font-weight-bold">
						{{ card.type.replace(`_`, ` `) | capitalize }}
					</div>
					<v-btn @click="statisticsOverlay = !statisticsOverlay" small class="ma-1"><svg-icon icon="forecast-chart" /></v-btn>
					<v-btn @click="editCard" small class="ma-1"><svg-icon icon="pencil" /></v-btn>
					<v-btn @click="removeCard" small class="ma-1" color="red"><svg-icon icon="trash-can" /></v-btn>
				</v-overlay>

				<v-overlay
					:absolute="true"
					:value="statisticsOverlay"
					class="fullscreen"
					:dark="$vuetify.theme.dark"
				>
					<ExploreStatistics :card-id="card._id" :section-id="section._id" @showStatisticsOverlay="statisticsOverlay = $event" />
				</v-overlay>
			</v-card>
		</template>
	</v-hover>
</template>

<script>
import ExploreListPost from "@/components/material/ExploreListPost"
import ExploreUserGroups from "@/components/material/ExploreUserGroups"
import ExploreCardTracking from "@/components/elements/ExploreCardTracking"
import ExploreCardUserGroups from "@/components/elements/ExploreCardUserGroups"
import ExploreStatistics from "@/components/material/ExploreStatistics"
import {mapActions, mapGetters} from "vuex";

export default {
	components: {
		ExploreListPost,
		ExploreUserGroups,
		ExploreCardTracking,
		ExploreCardUserGroups,
		ExploreStatistics,
	},
	computed: {
		...mapGetters([`getExploreSection`,`getExploreSectionCard`, `isDarkMode`]),
		section() {
			return this.getExploreSection(this.sectionIndex)
		},
		card() {
			return this.getExploreSectionCard(this.sectionIndex, this.cardIndex)
		},
	},
	data() {
		return {
			cardItems: {},
			statisticsOverlay: false,
			editing: false,
			icons: [
				`star`,
				`agree`,
				`news`,
				`play`,
			],
			edit: {
				_id: null,
				content: {
					title: null,
					icon: null,
					icon_url: null
				},
				date_created: null,
				date_modified: null,
				enabled: null,
				gradient: null,
				image_url: null,
				image_url_mobile: null,
				internal_url: null,
				link_url: null,
				user_groups: [],
				type: [],
			},
		}
	},
	methods: {
		...mapActions([`deleteCard`, `saveCard`]),
		okCard() {
			this.editing = false
			this.saveCard({sectionIndex: this.sectionIndex, cardIndex: this.cardIndex, card: this.edit})
		},
		cancelCard() {
			this.editing = false
		},
		editCard() {
			this.edit = JSON.parse(JSON.stringify(this.card))
			this.editing = true
		},
		removeCard() {
			this.$confirm(`Delete`, `Are you sure you want to delete this card?`)
				.then(() => this.deleteCard({sectionIndex: this.sectionIndex, cardIndex: this.cardIndex}), ()=>{})
		},
	},
	name: `MaterialFeedCardShortcut`,
	props: {
		sectionIndex: {
			type: Number,
		},
		cardIndex: {
			type: Number,
		},
		tracking: {
			type: Object,
			default: () => ({})
		},
	},
}
</script>