<template>
	<v-card width="100%" height="100%">
		<v-btn fab icon right top absolute class="mr-n11" @click="close" :loading="loading">
			<svg-icon style="font-size: 24px;" icon="cross-circle" />
		</v-btn>

		<v-simple-table dense>
			<template v-slot:default>
				<thead>
				<tr>
					<th class="text-left">Author</th>
					<th class="text-left">Title</th>
					<th class="text-left">Text</th>
					<th class="text-right">Viewed</th>
					<th class="text-right">Clicked</th>
					<th class="text-right">CTR %</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="item in items"
					:key="item.key"
				>
					<td class="text-left">{{ item.post.author.at_name }}</td>
					<td class="text-left">{{ item.post.title }}</td>
					<td class="text-left">{{ item.post.text }}</td>
					<td class="text-right">{{ item.count.post_view }}</td>
					<td class="text-right">{{ item.count.post_click }}</td>
					<td class="text-right">{{ (item.count.post_view / item.count.post_click) || `` }}</td>
				</tr>
				</tbody>
			</template>
		</v-simple-table>
	</v-card>
</template>

<script>

export default {
	data() {
		return {
			items: [],
			color: null,
			loading: false,
		}
	},
	methods: {
		close() {
			/* eslint-disable-next-line */
			this.$emit('showStatisticsOverlay', false)
		},
		loadStatistics() {
			this.$httpInt.get(`/v2/app/feed/explore/section/${this.sectionId}/card/${this.cardId}/post/statistics`)
				.then(res => this.items = res.data.result)
				.finally(() => this.loading = false)
		},
	},
	name: `ExploreStatistics`,
	props: {
		sectionId: {
			type: String,
			required: true,
		},
		cardId: {
			type: String,
			required: true,
		},
	},
	mounted() {
		this.loading = true
		this.loadStatistics()
	},
}
</script>